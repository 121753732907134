import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { priceRez, grids, tides, dopprice } from '../redux/actions';
import grid from "../redux/actions/db/grid.json";
import tide from "../redux/actions/db/tide.json";
import dopp from "../redux/actions/db/dopprices.json";
import pricerezult from "../redux/actions/db/pricerezult.json";

function FormParametr(props) {
    const [profile, setProfile] = useState("Россия, Турция");


    let prD = useDispatch();
    let oknoD = useDispatch();
    let stvorkaD = useDispatch();
    let gridD = useDispatch();
    let gridRez = useDispatch();
    let tideD = useDispatch();
    let tideRez = useDispatch();
    let widthD = useDispatch();
    let setka = useDispatch();
    let dismanting = useDispatch();
    let profiles = useDispatch();
    let result = useDispatch();

    let widthS = useSelector((state) => state.windowWidth);
    let heightS = useSelector((state) => state.windowHeight);
    let okno = useSelector((state) => state.okno);
    let stvorka = useSelector((state) => state.stvorka);
  //  let grid = useSelector((state) => state.grid);
  //  let tide = useSelector((state) => state.tide);
  //  let dopp = useSelector((state) => state.dopp);
    useEffect(()=>{
        if (oknoD.rez === 'Одностворчатое окно') {
         //   widthD({ type: "WIDTH", payload: 500 })
        } else if (oknoD.rez === 'Двустворчатое окно') {
          //  widthD({ type: "WIDTH", payload: 1000 })
        } else if (oknoD.rez === 'Трехстворчатое окно') {
            widthD({ type: "WIDTH", payload: 1600 })
        }

    },[okno.rez])

    useEffect(() => {
      //  prD(priceRez(profile, okno, stvorka, widthS.rez, heightS.rez));
        result({type:"REZULT",payload:pricerezult,profile:profile,okno:okno.rez,stvorka:stvorka.rez,widthS:widthS.rez,heightS:heightS.rez})
    }, [profile, okno.rez, stvorka.rez, widthS.rez, heightS.rez]);




    return (
        <div>
            <h5>{okno.rez}</h5>
            <div className='row'>
                <div className='col-sm'>
                    <label htmlFor="types">Тип окна</label>
                    <select id="types" onChange={(e)=>{
                        if(e.target.value === "Одностворчатое окно"){
                            oknoD({type: "OKNO", payload: e.target.value});
                            widthD({ type: "WIDTH", payload: "500" });
                            stvorkaD({ type: "STVORKA", payload: "глухое" })
                        }else if(e.target.value === "Двустворчатое окно"){
                            oknoD({type: "OKNO", payload: e.target.value});
                            widthD({ type: "WIDTH", payload: '1000' });
                            stvorkaD({ type: "STVORKA", payload: "Поворотное" })
                        }else if(e.target.value === "Трехстворчатое окно"){
                            oknoD({type: "OKNO", payload: e.target.value});
                            widthD({ type: "WIDTH", payload: "1600" });
                            stvorkaD({ type: "STVORKA", payload: "Поворотно-откидное" })
                        }
                    }}  className="select-right-prof form-control custom-select">
                        <option onClick={(e)=> {

                        }} value="Одностворчатое окно"  className="optionProfA">Одностворчатое окно</option>
                        <option onClick={(e)=> {


                        }} value="Двустворчатое окно" className="optionProfB">Двустворчатое окно</option>
                        <option onClick={(e)=> {

                        }} value="Трехстворчатое окно" className="optionProfC">Трехстворчатое окно</option>
                    </select>
                </div>
                <div className='col-sm'>
                    <label htmlFor="prof">Профиль</label>
                    <select id="prof" onChange={(e) => { setProfile(e.target.value);  }} className="select-right-prof form-control custom-select">
                        <option value="Россия, Турция" onClick={(e)=>profiles({ type: "PROFILE", payload: e.target.value,options:e.target.id})} id = {0} className="optionProfA">Украина, Болгария 3-к 60 мм. </option>
                        <option value="3-камерный" onClick={(e)=>profiles({ type: "PROFILE", payload: e.target.value,options:e.target.id})} id = {1} className="optionProfBA">Украина, Болгария 5-к 70 мм. </option>
                        <option value="3-камерный" onClick={(e)=>profiles({ type: "PROFILE", payload: e.target.value,options:e.target.id})} id = {2} className="optionProfBB">Brugmann 5-к 73 mm.</option>
                        <option value="5-камерный" onClick={(e)=>profiles({ type: "PROFILE", payload: e.target.value,options:e.target.id})} id = {3} className="optionProfC">Streamline 5-к 76 mm.</option>
                        <option value="5-камерный" onClick={(e)=>profiles({ type: "PROFILE", payload: e.target.value,options:e.target.id})} id = {4} className="optionProfCA">bluEvolution 6-к 92 mm.</option>
                    </select>
                </div>
                <div className='col-sm'>
                    <label htmlFor="stv">Окно</label>
                    <select id="stv" onChange={(e) => { stvorkaD({ type: "STVORKA", payload: e.target.value }) }} className="select-right form-control custom-select">
                        {okno.rez === "Одностворчатое окно" ? <option key='114' value="глухое" className="optionA">Глухое</option> : ""}
                        <option value="Поворотное" className="optionB">Поворотное</option>
                        <option value="Поворотно-откидное" className="optionC">Поворотно-откидное</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm'>
                    <label className="" htmlFor="podok">Подоконник</label>
                    <select onChange={(e) => gridRez({ type: "GRIDREZ", payload: e.target.value })} className="select-right-podok form-control custom-select" id="podok">
                        <option value={0} className="option-podok-white-no">Нет</option>
                        {
                            grid.map((x) =>
                                <option key={x.grid_id + "grid-white"} value={x.white} className={"option-podok-white-" + x.grid_id}> Белый {x.size} мм.</option>

                            )
                        }
                        {
                            grid.map((p) =>
                                <option key={p.grid_id + "grid-color"} value={p.color} className={"option-podok-color-" + p.grid_id + 2}> Цветной {p.size} мм.</option>

                            )
                        }

                    </select>
                </div>
                <div className='col-sm'>
                    <label className="" htmlFor="otliv">Отлив</label>
                    <select onChange={(e) => tideRez({ type: "TIDEREZ", payload: e.target.value })} className="select-right-otliv form-control custom-select" id="otliv">
                        <option value={0} className="option-otliv-white-no">Нет</option>
                        {
                            tide.map((white) =>
                                <option key={white.tide_id + "tide-white"} value={white.white} className={"option-podok-color-" + white.tide_id + 4}> Белый {white.size} мм.</option>
                            )
                        }
                        {
                            tide.map((color) =>
                                <option key={color.tide_id + "tide-color"} value={color.color} className={"option-podok-color-" + color.tide_id + 6}> Цветной {color.size} мм.</option>
                            )
                        }
                    </select>
                </div>
                <div className='col-sm'>
                    <label htmlFor="setka">Сетка</label>
            {dopp.map((x) => <div key={x.id + "dopp"}>

                <select onChange={(e) => setka({ type: "SETKA", payload: e.target.value })} id="setka" className="select-right-setka form-control custom-select">
                    <option value={0} className="option-setka-no">Нет</option>
                    <option value={x.grid} className="option-setka-yes">Да</option>
                </select><label htmlFor="dimontaj">Демонтаж-монтаж</label>
                <select onChange={(e) => dismanting({ type: "DISMANTING", payload: e.target.value })} id="dimontaj" className="select-right-dimontaj form-control custom-select">
                    <option value={0} className="option-dimontaj-no">Нет</option>
                    <option value={x.dismanting} className="option-dimontaj-yes">Да</option>
                </select>
            </div>)

            }
                </div>
            </div>


            

        </div>
    );
}

export default FormParametr;