import React, { useEffect, useState } from 'react';
import Colleft from './colLeft/Colleft';
import ColRight from './colRight/ColRight';
import Rezult from './rezult/Rezult';
import { useSelector } from 'react-redux';
import "./css/style.css"

export default function App(props) {



  let gridRez = useSelector((state)=>state.gridRez);
  let tideRez = useSelector((state)=>state.tideRez);
  let setka = useSelector((state)=>state.setka);
  let dismanting = useSelector((state)=>state.dismanting);
  let result = useSelector((state)=>state.result);


  return (
    <div className="App container-fluid">  
      <div className = "container position-relative">
        <div className="row row-left-call">
          <Colleft />
          <Rezult display = "min" setka = {setka} dismanting = {dismanting} tideRez={tideRez} priceRezult={parseInt(result.znacenie)} gridRez={gridRez} />
          <ColRight getdopp={props.getdopp} />
        </div>
      </div>
    </div>
  );
}





 
