import React from 'react';
import IconWind from '../iconWind/IconWind';
import FormParametr from '../formParametr/FormParametr';

export default function ColRight() {

  return (
    <div className="col-sm  col-right-call">
      <FormParametr />
    </div>
  );
}




